var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{class:_vm.elevation,attrs:{"show-select":_vm.showSelect,"headers":_vm.headers,"items":_vm.dataTable,"single-expand":false,"expanded":_vm.controlExpanded,"items-per-page":30,"page":_vm.currentPageComputed,"sort-by":'created_at',"sort-desc":true,"options":_vm.tableOptions,"hide-default-footer":"","item-key":"id","show-expand":_vm.showExpand,"disable-sort":""},on:{"update:expanded":function($event){_vm.controlExpanded=$event},"update:page":function($event){_vm.currentPageComputed=$event}},scopedSlots:_vm._u([{key:"top",fn:function({ pagination, options }){return [_vm._t("insideTable"),_vm._t("paginationTable",null,{"pagination":pagination,"options":options}),_vm._t("componentDelete")]}},{key:"item.number",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-left"},[_vm._t("iconInsideColumn",null,{"item":item}),_c('div',[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('a',{class:{
                            'black--text font-weight-bold': (item.wholesale_wc_id_user !== 0 && item.wholesale_wc_id_user !== null),
                            'secondary--text': (item.wholesale_wc_id_user === 0 || item.wholesale_wc_id_user === null)
                            },attrs:{"href":`https://etiquecosas.com.ar/wp-admin/post.php?post=${item.id_external}&action=edit`,"target":"_blanck"}},[_vm._v(" "+_vm._s(item.id_external)+" ")]),(item.wholesale_wc_id_user !== 0 && item.wholesale_wc_id_user !== null)?_c('span',{staticClass:"ml-2 font-weight-bold",staticStyle:{"font-size":"10px"}},[_vm._v("(May.)")]):_vm._e()]),_c('div',[_c('div',{staticClass:"mainOrderLink",on:{"click":function($event){return _vm.getMainOrder(item.main_order)}}},[_vm._v(" "+_vm._s(item.main_order)+" ")]),_c('div',{staticClass:"relatedOrdersLink d-flex flex-column"},_vm._l((item.orders_related),function(order_related,index){return _c('span',{key:index,on:{"click":function($event){return _vm.getMainOrder(order_related.id_external)}}},[_vm._v(_vm._s(order_related.id_external))])}),0)])])],2)]}},{key:"item.state",fn:function({ item }){return [(
                item.order_status.id === 5 &&
                _vm.stringCompare(item.shipping_line)
            )?_c('v-chip',{staticClass:"px-3 caption",attrs:{"color":_vm.getColor({
                    state: item.order_status,
                    shipping: item.shipping_line,
                }),"dark":""},on:{"click":function($event){return _vm.handleState(item)}}},[_vm._v(" "+_vm._s(item.order_status.name)+" para retirar 😍 ")]):(
                item.order_status.id === 5 &&
                !_vm.stringCompare(item.shipping_line)
            )?_c('v-chip',{staticClass:"px-3 caption",attrs:{"color":_vm.getColor({
                    state: item.order_status,
                    shipping: item.shipping_line,
                }),"dark":""},on:{"click":function($event){return _vm.handleState(item)}}},[_vm._v(" "+_vm._s(item.order_status.name)+" para despachar 😍 ")]):_c('v-chip',{staticClass:"px-3 caption",attrs:{"color":_vm.getColor({
                    state: item.order_status,
                    shipping: item.shipping_line,
                }),"dark":""},on:{"click":function($event){return _vm.handleState(item)}}},[_vm._v(" "+_vm._s(item.order_status.name)+" ")])]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.returnDate(item.created_at))+" ")]}},{key:"expanded-item",fn:function({ headers, item }){return [_vm._t("expandedItemTable",null,{"item":item,"headers":headers})]}},{key:"item.data-table-preview",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.preview(item)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}},(_vm.details)?{key:"item.data-table-expand",fn:function({ item, isExpanded, expand }){return [_vm._t("columnExpandTable",null,{"item":item,"isExpanded":isExpanded,"expand":expand})]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }