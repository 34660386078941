import { render, staticRenderFns } from "./AppTable.vue?vue&type=template&id=3c83c10c&scoped=true"
import script from "./AppTable.vue?vue&type=script&lang=js"
export * from "./AppTable.vue?vue&type=script&lang=js"
import style0 from "./AppTable.vue?vue&type=style&index=0&id=3c83c10c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c83c10c",
  null
  
)

export default component.exports