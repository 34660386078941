import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllOrders = async (token, page, q, dates, status, user, mayorista) => {
  try {
    let URL = `/orders?perPage=30&page=${page}`
    if (dates !== undefined && dates !== '' && dates !== [] && dates.length > 0) { URL += `&betweendates=${dates}` }
    if (status !== undefined && status !== '' && status !== 0) { URL += `&orderstatus=${status}` }
    if (user !== undefined && user !== '' && user !== 0) { URL += `&users=${user}` }
    if (mayorista !== undefined && mayorista !== '' && mayorista !== 0) { URL += `&mayorista=${mayorista}` }
    if (q !== undefined && q !== '') { URL += `&q=${q}` }
    const res = await axios.get(URL, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getOrder = async (order) => {
  try {
    const res = await axios.get(`/orders/${order}`)
    return res
  } catch (err) {
    throw err.response
  }
}

export const getOrderIdEncripted = async (order) => {
  try {
    const res = await axios.post('orders/hashed', {
      hash: order
    })
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateCommentary = async (order, token) => {
  try {
    const res = await axios.put(`/order_commentary/${order.id}`, order, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateStatus = async (order, token) => {
  try {
    const res = await axios.put(`/order_status/${order.id}`, order, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateUser = async (clientId, data, token) => {
  try {
    const res = await axios.put(`/clients/orders/${clientId}`, data, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const asignUser = async (userId, orderId, token) => {
  const uID = userId !== 0 ? userId : null
  try {
    const res = await axios.post(`orders/${orderId}/user/${uID}`, '', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
// cambiar datos
export const asignMultipleUsers = async (userId, ordersId, token) => {
  try {
    const data = {
      user_id: userId !== 0 ? String(userId) : null,
      orders_id: ordersId
    }
    const res = await axios.post('orders/assign_user_to_multiple_orders', data, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const generatePDF = async (orderId, token) => {
  try {
    const data = {
      order_id: orderId
    }
    const res = await axios.post('https://www.api.etiquecosas.com.ar/generatePDF', data)
    return res
  } catch (err) {
    throw err.response
  }
}
