<template>
    <v-data-table
        :show-select="showSelect"
        v-model="selected"
        :headers="headers"
        :items="dataTable"
        :single-expand="false"
        :expanded.sync="controlExpanded"
        :items-per-page="30"
        :page.sync="currentPageComputed"
        :sort-by="'created_at'"
        :sort-desc="true"
        :options="tableOptions"
        hide-default-footer
        item-key="id"
        :show-expand="showExpand"
        disable-sort
        :class="elevation"
    >
        <template v-slot:top="{ pagination, options }">
            <slot name="insideTable"></slot>
            <slot
                name="paginationTable"
                :pagination="pagination"
                :options="options"
            ></slot>
            <slot name="componentDelete"></slot>
        </template>

        <template v-slot:item.number="{ item }">
            <div class="d-flex justify-left">
                <slot name="iconInsideColumn" :item="item"></slot>
                <div>
                    <div class="d-flex flex-row align-center">
                        <a
                            class=""
                            :class="{
                                'black--text font-weight-bold': (item.wholesale_wc_id_user !== 0 && item.wholesale_wc_id_user !== null),
                                'secondary--text': (item.wholesale_wc_id_user === 0 || item.wholesale_wc_id_user === null)
                                }"
                            :href="`https://etiquecosas.com.ar/wp-admin/post.php?post=${item.id_external}&action=edit`"
                            target="_blanck"
                        >
                            {{ item.id_external }}
                        </a>
                        <span class="ml-2 font-weight-bold" style="font-size: 10px;" v-if="item.wholesale_wc_id_user !== 0 && item.wholesale_wc_id_user !== null">(May.)</span>
                    </div>
                    <div>
                        <div
                            class="mainOrderLink"
                            @click="getMainOrder(item.main_order)"
                        >
                            {{ item.main_order }}
                        </div>
                        <div class="relatedOrdersLink d-flex flex-column">
                            <span
                                @click="getMainOrder(order_related.id_external)"
                                v-for="(
                                    order_related, index
                                ) in item.orders_related"
                                :key="index"
                                >{{ order_related.id_external }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:item.state="{ item }">
            <v-chip
                v-if="
                    item.order_status.id === 5 &&
                    stringCompare(item.shipping_line)
                "
                :color="
                    getColor({
                        state: item.order_status,
                        shipping: item.shipping_line,
                    })
                "
                dark
                class="px-3 caption"
                @click="handleState(item)"
            >
                {{ item.order_status.name }} para retirar 😍
            </v-chip>
            <v-chip
                v-else-if="
                    item.order_status.id === 5 &&
                    !stringCompare(item.shipping_line)
                "
                :color="
                    getColor({
                        state: item.order_status,
                        shipping: item.shipping_line,
                    })
                "
                dark
                class="px-3 caption"
                @click="handleState(item)"
            >
                {{ item.order_status.name }} para despachar 😍
            </v-chip>
            <v-chip
                v-else
                :color="
                    getColor({
                        state: item.order_status,
                        shipping: item.shipping_line,
                    })
                "
                dark
                class="px-3 caption"
                @click="handleState(item)"
            >
                {{ item.order_status.name }}
            </v-chip>
        </template>

        <template v-slot:item.created_at="{ item }">
            {{ returnDate(item.created_at) }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
            <slot
                name="expandedItemTable"
                :item="item"
                :headers="headers"
            ></slot>
        </template>

        <template v-slot:item.data-table-preview="{ item }">
            <v-btn @click="preview(item)" icon>
                <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
        </template>

        <template
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
            v-if="details"
        >
            <slot
                name="columnExpandTable"
                :item="item"
                :isExpanded="isExpanded"
                :expand="expand"
            ></slot>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mdiEyeOutline } from "@mdi/js";
import moment from "moment";
export default {
    name: "AppTable",
    created() {
        this.initialize();
    },
    props: {
        dataTable: Array,
        headers: Array,
        itemKey: { type: String, default: "number" },
        getColor: Function,
        expanded: Array,
        elevation: String,
        currentPage: Number,
        tableOptions: Object,
        showSelect: Boolean,
        clearSelected: Boolean,
        details: {
            type: Boolean,
            default: true,
        },
        showExpand: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        eyeicon: mdiEyeOutline,
        selected: [],
        doneClearingSelected: false,
    }),
    methods: {
        initialize() {
            this.$store.commit("setTextSearch", this.getTextSearch);
        },

        handleState(val) {
            this.$emit("handleState", {
                item: { fn: "showChangeStatus", text: "Cambiar Estado" },
                option: 0,
                itemPreview: val,
            });
        },

        preview(data) {
            if (this.$router.currentRoute.path === "/products") {
                this.$router.push({ path: `/new_product/${data.id}` });
            } else {
                this.$router.push({ path: `/preview/${data.id}` });
            }
        },

        returnDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        stringCompare(sentence) {
            return (
                sentence.includes("Retiro oficina") ||
                sentence.includes("Retiro por local")
            );
        },
        getMainOrder(order) {
            this.$emit("getMainOrder", order);
        },
    },
    watch: {
        selected: {
            handler() {
                const ids = this.selected.map((i) => i.id);
                this.$emit("idsSelected", ids);
                return ids;
            },
        },
        clearSelected: {
            handler() {
                if (this.clearSelected) {
                    this.selected = [];
                    this.$emit("doneClearingSelected");
                }
            },
        },
    },
    computed: {
        ...mapState(["textSearch"]),
        ...mapGetters(["getTextSearch"]),

        controlExpanded: {
            get() {
                return this.expanded;
            },
            set() {
                this.$emit("handleExpandedItem");
            },
        },

        currentPageComputed: {
            get() {
                return this.currentPage;
            },
            set(val) {
                this.$emit("updateOptions", val);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.v-data-table
    > ::v-deep.v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none !important;
    background-color: #f3f3f3;
}
.mainOrderLink {
    color: black;
    font-size: 0.8rem;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
}
.relatedOrdersLink span {
    color: #aeaeae;
    font-size: 0.8rem;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
}
</style>
